import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { useRequest } from "@coralblack/flax";
import { AuthLayout } from "@layouts/Auth";
import useDebounce from "@hooks/useDebounce";
import { authApi } from "maven-lib/dist/states/apis/auth";
import { orchestraMsaApi } from "maven-lib/dist/states/apis/orchestra";
import { userApi } from "maven-lib/dist/states/apis/user";
import { DeviceVerificationCode } from "./DeviceVerificationCode";
import { preparedAuthLink as preparedAuthLinkSSO, preparedCallbackLink, retrieveSSOPayload } from "./SSO";
import { CrButton } from "@maven-surface/components/base/CrButton";
import { CrDialog } from "@maven-surface/components/base/CrDialog";
import { CrInput } from "@maven-surface/components/base/CrInput";
import { CrInputGroup } from "@maven-surface/components/base/CrInputGroup";
import { useRoute } from "@maven-surface/hooks";
import { DuplicateLoginDialog } from "@maven-surface/pages/auth/layout/dialogs/DuplicateLoginDialog";
import { InitEmailVerifyPasswordDialog as InitEmailVerifyPasswordDialogLegacy } from "@maven-surface/pages/auth/layout/dialogs/InitEmailVerifyPasswordDialog";
import { InitPasswordDialog as InitPasswordDialogLegacy } from "@maven-surface/pages/auth/layout/dialogs/InitPasswordDialog";
import { UpdatePasswordDialog } from "@maven-surface/pages/auth/layout/dialogs/UpdatePasswordDialog";
import { AUTH_TOKEN_CODE, SSO_AUTH_TOKEN_CODE, SSO_USER_DEVICE_CODE } from "@maven-msg/code";
import { SSO_USER_VERIFICATION_CODE_CODE } from "@maven-msg/code/sso/userVerificationCode";
import { AUTH_TOKEN_LANG, COMMON_LANG, SSO_USER_VERIFICATION_CODE_LANG } from "@maven-msg/lang";
import { SSO_USER_LANG } from "@maven-msg/lang/sso/user";
import { MvnMessage } from "@maven-msg/lib/Message";
import { MavenService } from "@maven-rest/common/common.types";
import { ReadableRefServiceType } from "@maven-rest/ref/Ref.conv";
import { RefServiceType } from "@maven-rest/ref/Ref.dto";
import { AuthTokenCriteria } from "@maven-rest/sso/AuthToken.dto";
import { AuthTokenFlax } from "@maven-rest/sso/AuthToken.flax";
import { UserFlax } from "@maven-rest/sso/User.flax";
import { UserVerificationCodeCriteria } from "@maven-rest/sso/UserVerificationCode.dto";
import { UserVerificationCodeFlax } from "@maven-rest/sso/UserVerificationCode.flax";
import { DEVICE_KEY, getCookie, sessionSlice } from "@maven-rest/states";
import { getCurrentDate } from "@maven-helper/functions/Misc.function";
import { isUserPasswordValid } from "@sso-lib/user.lib";
import { v4 as uuidv4 } from "uuid";

const queue: Array<string> = [];
const IS_NEED_NOTIFY_LAST_LOGINTIME = "isNeedNotifyLastLoginTime";

export function LegacySignIn() {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { query } = useRoute();
  const redirectTo = query.redirect || (state as any)?.from?.pathname;

  const [emailVal, setEmailVal] = useState(String(query.email || ""));
  const [passwordVal, setPasswordVal] = useState("");
  const [initPasswordDialogVisibility, setInitPasswordDialogVisibility] = useState(false);
  const [initEmailVerifyPasswordDialogVisibility, setInitEmailVerifyPasswordDialogVisibility] = useState(false);
  const [updatePasswordDialogVisibility, setUpdatePasswordDialogVisibility] = useState(false);
  const [duplicateLoginDialogVisibility, setDuplicateLoginDialogVisibility] = useState(false);
  const [isPasswordConfiguredState, setIsPasswordConfiguredState] = useState("");
  const [verificationCodeUuid, setVerificationCodeUuid] = useState<string>("");

  const service = useMemo(() => {
    const ssoPayload = retrieveSSOPayload();

    if (!ssoPayload?.redirect) {
      return MavenService.MavenClinicalCloud;
    }

    if (process.env.REACT_APP_DOCS_FE_URL && ssoPayload.redirect.includes(process.env.REACT_APP_DOCS_FE_URL)) {
      return MavenService.MavenDocs;
    }

    if (process.env.REACT_APP_TMF_FE_URL && ssoPayload.redirect.includes(process.env.REACT_APP_TMF_FE_URL)) {
      return MavenService.MavenTMF;
    }

    if (process.env.REACT_APP_SAFETY_FE_URL && ssoPayload.redirect.includes(process.env.REACT_APP_SAFETY_FE_URL)) {
      return MavenService.MavenSafety;
    }

    if (
      (process.env.REACT_APP_CDMS_FE_URL && ssoPayload.redirect.includes(process.env.REACT_APP_CDMS_FE_URL)) ||
      (process.env.REACT_APP_CDMS_FE_REAL_URL && ssoPayload.redirect.includes(process.env.REACT_APP_CDMS_FE_REAL_URL)) ||
      (process.env.REACT_APP_CDMS_FE_BETA_URL && ssoPayload.redirect.includes(process.env.REACT_APP_CDMS_FE_BETA_URL)) ||
      (process.env.REACT_APP_CDMS_FE_SANDBOX_URL && ssoPayload.redirect.includes(process.env.REACT_APP_CDMS_FE_SANDBOX_URL))
    ) {
      return MavenService.MavenCDMS;
    }

    if (process.env.REACT_APP_VDR_FE_URL && ssoPayload.redirect.includes(process.env.REACT_APP_VDR_FE_URL)) {
      return MavenService.MavenVDR;
    }

    if (process.env.REACT_APP_CONVERTER_FE_URL && ssoPayload.redirect.includes(process.env.REACT_APP_CONVERTER_FE_URL)) {
      return MavenService.MavenConverter;
    }

    if (process.env.REACT_APP_BUILDER_FE_URL && ssoPayload.redirect.includes(process.env.REACT_APP_BUILDER_FE_URL)) {
      return MavenService.MavenBuilder;
    }

    return MavenService.MavenClinicalCloud;
  }, []);

  const preparedAuthLink = (url: string) =>
    preparedAuthLinkSSO(
      `${url}${query.redirect ? `${url.includes("?") ? "&" : "?"}redirect=${encodeURIComponent(String(query.redirect))}` : ""}`
    );

  const { request: sendVerificationCodeForDeviceRegistrationRequest, response: sendVerificationCodeForDeviceRegistrationResponse } =
    useRequest(UserVerificationCodeFlax.createUserVerificationCodeForDeviceRegistration({ email: emailVal }), {
      success: (resp) => {
        setVerificationCodeUuid(resp.userVerificationCode.uuid);
        setInitPasswordDialogVisibility(false);
        setInitEmailVerifyPasswordDialogVisibility(false);
        setUpdatePasswordDialogVisibility(false);
        setDuplicateLoginDialogVisibility(false);

        return MvnMessage(SSO_USER_VERIFICATION_CODE_LANG.SSO_USER_VERIFICATION_CODE_USER_DEVICE_EMAIL_SENT);
      },
      error: (error) => {
        return { title: "Send Mail Error", message: error?.message || "An error has occurred." };
      },
    });

  const { request: loginRequest, response: loginResponse } = useRequest(
    { ...authApi.createAuthToken },
    {
      success: (resp) => {
        sessionStorage.setItem(IS_NEED_NOTIFY_LAST_LOGINTIME, "true");
        dispatch(
          sessionSlice.actions.signIn({
            ...resp,
            redirectTo: retrieveSSOPayload()
              ? preparedCallbackLink({ token: resp.authToken.token, expiryAt: resp.authToken.expiryAt })
              : redirectTo,
          })
        );
      },
      error: (error) => {
        if (error?.code === AUTH_TOKEN_CODE.INITIAL_PASSWORD_NOT_SET.code) {
          setInitPasswordDialogVisibility(true);
        } else if (error?.code === AUTH_TOKEN_CODE.INITIAL_PASSWORD_EMAIL_VERIFY_NOT_SET.code) {
          setInitEmailVerifyPasswordDialogVisibility(true);
        } else if (error?.code === AUTH_TOKEN_CODE.PASSWORD_CHANGE_CYCLE_EXCEED.code) {
          setUpdatePasswordDialogVisibility(true);
        } else if (error?.code === AUTH_TOKEN_CODE.PREVIOUS_AUTH_TOKEN_ALIVE.code) {
          setDuplicateLoginDialogVisibility(true);
        } else if (error?.code === SSO_USER_DEVICE_CODE.SSO_USER_DEVICE_CODE_USER_DEVICE_TOKEN_INVALID.code) {
          sendVerificationCodeForDeviceRegistrationRequest();
          return;
        }

        return { title: "Login Error", message: error?.message || "An error has occurred." };
      },
    }
  );

  const cache = (key: string, val?: boolean): boolean | null => {
    const k = `auth:cfg:${key}`;

    if (val !== undefined) {
      localStorage.setItem(k, JSON.stringify({ val, exp: new Date().getTime() + (val === true ? 3600 * 24 * 30 : 5) * 1000 }));
    }

    const cached = localStorage.getItem(k);
    if (!cached) return null;

    try {
      const { val, exp } = JSON.parse(cached);
      if (new Date().getTime() > exp) return null;
      return val;
    } catch (e) {
      return null;
    }
  };

  const { request: checkIsPasswordConfiguredReq, cancel: cancelCheckIsPasswordConfigured } = useRequest(
    { ...userApi.checkIsPasswordConfigured, cacheMaxAge: 3600 * 24 },
    {
      success: (resp) => {
        cache(resp.email, resp.isPasswordConfigured);

        const q = queue.pop();

        if (q && typeof cache(q) !== "boolean") {
          const res = checkIsPasswordConfigured(q);
          if (res === false) queue.push(q);
        }

        setIsPasswordConfiguredState(uuidv4());
      },
    }
  );

  const { request: wakenMss } = useRequest({ ...orchestraMsaApi.waken });

  const checkIsPasswordConfigured = (email: string) => {
    const cached = cache(email);
    if (typeof cached === "boolean") return cached;

    checkIsPasswordConfiguredReq({ data: { email } });
    return true;
  };

  const login = (email: string, password: string, service: MavenService, deviceToken: string) => {
    loginRequest({ data: { email, password, service, checkPreviousAuthToken: true, deviceToken } });
  };

  return (
    <AuthLayout>
      <div className="header">
        <h2 className="title">Sign in</h2>
        <p className="help">
          Any problem?{" "}
          <a href="https://www.jnpmedi.com" target="_blank" rel="noopener noreferrer">
            Ask us anything!
          </a>
        </p>
      </div>
      <InitPasswordDialogLegacy
        header={ReadableRefServiceType(RefServiceType.Suite)}
        messages={{
          dialogTitle: {
            default: MvnMessage(AUTH_TOKEN_LANG.INITIAL_SETUP_TITLE_PASSWORD_SET),
          },
          label: {
            password: MvnMessage(AUTH_TOKEN_LANG.PASSWORD),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.CONFIRM_PASSWORD),
          },
          description: {
            password: MvnMessage(AUTH_TOKEN_LANG.PASSWORD_VALIDATION),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.PASSWORD_NOT_MATCH),
          },
          placeholder: {
            password: MvnMessage(AUTH_TOKEN_LANG.NEW_PASSWORD_PLACEHOLDER),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.NEW_PASSWORD_CONFIRM_PLACEHOLDER),
          },
          response: {
            passwordUpdateError: (error) =>
              MvnMessage(AUTH_TOKEN_LANG.PASSWORD_CHANGE_FAILED, { message: error?.message || "An error has occurred." }),
          },
          errorMessage: {
            password: MvnMessage(AUTH_TOKEN_LANG.CHANGE_PASSWORD_INVALID_SAME_EMAIL),
          },
        }}
        visibility={initPasswordDialogVisibility}
        email={emailRef}
        currentPassword={passwordRef}
        passwordUpdatedEventHandler={(password) => {
          const deviceToken = getCookie(DEVICE_KEY);

          setInitPasswordDialogVisibility(false);
          setPasswordVal(password);
          loginRequest({ data: { email: emailRef, password, service, deviceToken } });
        }}
        cancelEventHandler={() => setInitPasswordDialogVisibility(false)}
      />
      <InitEmailVerifyPasswordDialogLegacy
        header={ReadableRefServiceType(RefServiceType.Suite)}
        key={`${emailRef.current?.value || "none"}`}
        messages={{
          dialogTitle: {
            default: MvnMessage(AUTH_TOKEN_LANG.INITIAL_SETUP_TITLE_EMAIL_VERIFY),
            codeSent: MvnMessage(AUTH_TOKEN_LANG.INITIAL_SETUP_TITLE_EMAIL_SENT),
            enterPassword: MvnMessage(AUTH_TOKEN_LANG.INITIAL_SETUP_TITLE_ENTER_PASSWORD),
          },
          button: {
            confirm: MvnMessage(COMMON_LANG.CONFIRM),
            cancel: MvnMessage(COMMON_LANG.CANCEL),
            verify: MvnMessage(COMMON_LANG.VERIFY),
            requestVerificationCode: MvnMessage(AUTH_TOKEN_LANG.REQUEST_VERIFICATION_CODE),
          },
          label: {
            email: MvnMessage(AUTH_TOKEN_LANG.EMAIL),
            verificationCode: MvnMessage(AUTH_TOKEN_LANG.VERIFICATION_CODE),
            passwordNew: MvnMessage(AUTH_TOKEN_LANG.PASSWORD),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.CONFIRM_PASSWORD),
          },
          placeholder: {
            email: MvnMessage(AUTH_TOKEN_LANG.VERIFICATION_CODE),
            verificationCode: MvnMessage(AUTH_TOKEN_LANG.VERIFICATION_PLACEHOLDER),
            passwordNew: MvnMessage(AUTH_TOKEN_LANG.NEW_PASSWORD_PLACEHOLDER),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.NEW_PASSWORD_CONFIRM_PLACEHOLDER),
          },
          response: {
            verificationCodeSent: MvnMessage(AUTH_TOKEN_LANG.EMAIL_SEND_SUCCEED),
            verificationCodeSendError: (error) => MvnMessage(AUTH_TOKEN_LANG.EMAIL_SEND_FAILED, { message: error }),
            verificationCodeConfirmed: MvnMessage(AUTH_TOKEN_LANG.INITIAL_SETUP_TITLE_ENTER_PASSWORD),
            verificationCodeTryLimitExceed: MvnMessage(AUTH_TOKEN_LANG.VERIFY_LIMIT_EXCEED),
            verificationCodeNotMatched: MvnMessage(AUTH_TOKEN_LANG.VERIFICATION_NOT_MATCH),
            passwordUpdateError: (error) =>
              MvnMessage(AUTH_TOKEN_LANG.PASSWORD_CHANGE_FAILED, { message: error || "An error has occurred." }),
          },
          alert: {
            passwordValidate: MvnMessage(AUTH_TOKEN_LANG.PASSWORD_VALIDATION),
            passwordNotMatched: MvnMessage(AUTH_TOKEN_LANG.PASSWORD_NOT_MATCH),
          },
          code: {
            verificationCodeTryLimitExceed: AUTH_TOKEN_CODE.VERIFY_CODE_LOCK.code!,
          },
        }}
        visibility={initEmailVerifyPasswordDialogVisibility}
        email={emailRef}
        passwordUpdatedEventHandler={(password) => {
          const deviceToken = getCookie(DEVICE_KEY);

          setInitEmailVerifyPasswordDialogVisibility(false);
          setPasswordVal(password);
          loginRequest({ data: { email: emailRef, password, service, deviceToken } });
        }}
        cancelEventHandler={() => setInitEmailVerifyPasswordDialogVisibility(false)}
        emailVal={emailVal}
      />
      <UpdatePasswordDialog
        header={ReadableRefServiceType(RefServiceType.Suite)}
        messages={{
          dialogTitle: {
            default: MvnMessage(AUTH_TOKEN_LANG.UPDATE_SETUP_TITLE_PASSWORD_SET),
          },
          label: {
            password: MvnMessage(AUTH_TOKEN_LANG.PASSWORD),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.CONFIRM_PASSWORD),
          },
          description: {
            password: MvnMessage(AUTH_TOKEN_LANG.PASSWORD_VALIDATION),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.PASSWORD_NOT_MATCH),
          },
          placeholder: {
            password: MvnMessage(AUTH_TOKEN_LANG.NEW_PASSWORD_PLACEHOLDER),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.NEW_PASSWORD_CONFIRM_PLACEHOLDER),
          },
          response: {
            passwordUpdateError: (error) =>
              MvnMessage(AUTH_TOKEN_LANG.PASSWORD_CHANGE_FAILED, { message: error?.message || "An error has occurred." }),
          },
          errorMessage: {
            password: MvnMessage(AUTH_TOKEN_LANG.CHANGE_PASSWORD_INVALID_SAME_EMAIL),
          },
        }}
        visibility={updatePasswordDialogVisibility}
        email={emailVal}
        currentPassword={passwordVal}
        updatePasswordDoneEventHandler={(password) => {
          const deviceToken = getCookie(DEVICE_KEY);

          setUpdatePasswordDialogVisibility(false);
          loginRequest({ data: { email: emailVal, password, service, deviceToken } });
        }}
        cancelEventHandler={() => {
          setVerificationCodeUuid("");
          setUpdatePasswordDialogVisibility(false);
        }}
      />
      <DuplicateLoginDialog
        header={ReadableRefServiceType(RefServiceType.Suite)}
        messages={{ dialogText: { default: MvnMessage(AUTH_TOKEN_LANG.LOGIN_DUPLICATE_TEXT) } }}
        visibility={duplicateLoginDialogVisibility}
        confirmEventHandler={() => {
          const deviceToken = getCookie(DEVICE_KEY);
          loginRequest({ data: { email: emailVal, password: passwordVal, service, deviceToken } });
        }}
        cancelEventHandler={() => {
          setVerificationCodeUuid("");
          setDuplicateLoginDialogVisibility(false);
        }}
      />
      <div className="form" style={{ display: !verificationCodeUuid ? "block" : "none" }}>
        <fieldset>
          <legend>Sign in</legend>
          <label htmlFor="email">
            <input
              ref={emailRef}
              defaultValue={emailVal}
              id="email"
              placeholder="Email"
              className="email"
              type="email"
              onBlur={() => {
                wakenMss();
              }}
              onChange={(e) => {
                setEmailVal(e.target.value);

                if (isEmail(e.target.value)) {
                  cancelCheckIsPasswordConfigured();

                  queue.push(String(e.target.value || "").toLowerCase());
                  checkIsPasswordConfigured(String(e.target.value || "").toLowerCase());
                }
              }}
              onKeyDown={(e) => {
                const deviceToken = getCookie(DEVICE_KEY);
                e.key === "Enter" &&
                  emailVal &&
                  !checkIsPasswordConfigured(emailVal) &&
                  loginRequest({
                    data: { email: emailRef, password: passwordRef, service, checkPreviousAuthToken: true, deviceToken },
                  });
              }}
            />
          </label>
          <Fragment key={isPasswordConfiguredState}>
            {cache(emailVal) !== false && (
              <label htmlFor="password">
                <input
                  ref={passwordRef}
                  id="password"
                  placeholder="Password"
                  className="password"
                  type="password"
                  onBlur={() => {
                    wakenMss();
                  }}
                  onChange={(e) => {
                    setPasswordVal(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    const deviceToken = getCookie(DEVICE_KEY);
                    e.key === "Enter" &&
                      emailVal &&
                      passwordVal &&
                      loginRequest({
                        data: { email: emailRef, password: passwordRef, service, checkPreviousAuthToken: true, deviceToken },
                      });
                  }}
                />
              </label>
            )}
          </Fragment>
        </fieldset>
        <div className="button">
          <CrButton
            loading={loginResponse.busy || sendVerificationCodeForDeviceRegistrationResponse.busy}
            disabled={loginResponse.busy || !emailVal || (!passwordVal && checkIsPasswordConfigured(emailVal))}
            color="primary"
            onClick={() => {
              const deviceToken = getCookie(DEVICE_KEY);
              emailVal &&
                loginRequest({ data: { email: emailRef, password: passwordRef, service, checkPreviousAuthToken: true, deviceToken } });
            }}
          >
            Sign in
          </CrButton>
          <p>
            <Link to={preparedAuthLink("/auth/password/find")}>Forgot Password?</Link>
          </p>
          {process.env.REACT_APP_FEATURE_SIGNUP === "true" && (
            <>
              <hr />
              <Link to={preparedAuthLink("/auth/sign-up")} className="cr-button primary">
                <em>Don't have an account?</em> Sign Up.
              </Link>
            </>
          )}
        </div>
      </div>
      {verificationCodeUuid && (
        <DeviceVerificationCode
          email={emailVal}
          password={passwordVal}
          service={service}
          verificationCodeUuid={verificationCodeUuid}
          login={login}
          loginResponseBusy={loginResponse.busy}
        />
      )}
    </AuthLayout>
  );
}

export function SignIn() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { query } = useRoute();
  const redirectTo = query.redirect || (state as any)?.from?.pathname;

  const [email, setEmail] = useState<string>(String(query.email || ""));
  const [password, setPassword] = useState<string>("");
  const [initPasswordDialogVisibility, setInitPasswordDialogVisibility] = useState<boolean>(false);
  const [initPasswordByEmailDialogVisibility, setInitPasswordByEmailDialogVisibility] = useState<boolean>(false);
  const [updatePasswordDialogVisibility, setUpdatePasswordDialogVisibility] = useState<boolean>(false);
  const [duplicateLoginDialogVisibility, setDuplicateLoginDialogVisibility] = useState<boolean>(false);
  const [verificationCodeUuid, setVerificationCodeUuid] = useState<string>("");
  const [needPasswordSetup, setNeedPasswordSetup] = useState<boolean>(false);

  const debounceEmail = useDebounce(email);

  const isLoginButtonEnabled = email && password;

  const service = useMemo(() => {
    const ssoPayload = retrieveSSOPayload();

    if (!ssoPayload?.redirect) {
      return MavenService.MavenClinicalCloud;
    }

    if (process.env.REACT_APP_DOCS_FE_URL && ssoPayload.redirect.includes(process.env.REACT_APP_DOCS_FE_URL)) {
      return MavenService.MavenDocs;
    }

    if (process.env.REACT_APP_TMF_FE_URL && ssoPayload.redirect.includes(process.env.REACT_APP_TMF_FE_URL)) {
      return MavenService.MavenTMF;
    }

    if (process.env.REACT_APP_SAFETY_FE_URL && ssoPayload.redirect.includes(process.env.REACT_APP_SAFETY_FE_URL)) {
      return MavenService.MavenSafety;
    }

    if (
      (process.env.REACT_APP_CDMS_FE_URL && ssoPayload.redirect.includes(process.env.REACT_APP_CDMS_FE_URL)) ||
      (process.env.REACT_APP_CDMS_FE_REAL_URL && ssoPayload.redirect.includes(process.env.REACT_APP_CDMS_FE_REAL_URL)) ||
      (process.env.REACT_APP_CDMS_FE_BETA_URL && ssoPayload.redirect.includes(process.env.REACT_APP_CDMS_FE_BETA_URL)) ||
      (process.env.REACT_APP_CDMS_FE_SANDBOX_URL && ssoPayload.redirect.includes(process.env.REACT_APP_CDMS_FE_SANDBOX_URL))
    ) {
      return MavenService.MavenCDMS;
    }

    if (process.env.REACT_APP_VDR_FE_URL && ssoPayload.redirect.includes(process.env.REACT_APP_VDR_FE_URL)) {
      return MavenService.MavenVDR;
    }

    if (process.env.REACT_APP_CONVERTER_FE_URL && ssoPayload.redirect.includes(process.env.REACT_APP_CONVERTER_FE_URL)) {
      return MavenService.MavenConverter;
    }

    if (process.env.REACT_APP_BUILDER_FE_URL && ssoPayload.redirect.includes(process.env.REACT_APP_BUILDER_FE_URL)) {
      return MavenService.MavenBuilder;
    }

    return MavenService.MavenClinicalCloud;
  }, []);

  const preparedAuthLink = (url: string) =>
    preparedAuthLinkSSO(
      `${url}${query.redirect ? `${url.includes("?") ? "&" : "?"}redirect=${encodeURIComponent(String(query.redirect))}` : ""}`
    );

  const { request: sendVerificationCodeForDeviceRegistrationRequest, response: sendVerificationCodeForDeviceRegistrationResponse } =
    useRequest(UserVerificationCodeFlax.createUserVerificationCodeForDeviceRegistration({ email }), {
      success: (resp) => {
        setVerificationCodeUuid(resp.userVerificationCode.uuid);
        setInitPasswordDialogVisibility(false);
        setInitPasswordByEmailDialogVisibility(false);

        return MvnMessage(SSO_USER_VERIFICATION_CODE_LANG.SSO_USER_VERIFICATION_CODE_USER_DEVICE_EMAIL_SENT);
      },
      error: (error) => {
        return { title: "Send Mail Error", message: error?.message || "An error has occurred." };
      },
    });

  const { request: loginRequest, response: loginResponse } = useRequest(AuthTokenFlax.createAuthTokenForTest({} as any), {
    success: (resp) => {
      const defaultRedirect = process.env.REACT_APP_AUTH_FE_URL ?? redirectTo;

      sessionStorage.setItem(IS_NEED_NOTIFY_LAST_LOGINTIME, "true");
      dispatch(
        sessionSlice.actions.signIn({
          ...resp,
          redirectTo: retrieveSSOPayload()
            ? preparedCallbackLink({ token: resp.authToken.token, expiryAt: resp.authToken.expiryAt })
            : defaultRedirect,
        })
      );
    },
    error: (error) => {
      if (error?.code === SSO_AUTH_TOKEN_CODE.SSO_AUTH_TOKEN_CREATE_INVALID_INIT_PASSWORD.code) {
        setInitPasswordDialogVisibility(true);
      } else if (error?.code === SSO_AUTH_TOKEN_CODE.SSO_AUTH_TOKEN_CREATE_INVALID_INIT_PASSWORD_BY_EMAIL_VERIFICATION.code) {
        setInitPasswordByEmailDialogVisibility(true);
      } else if (error?.code === SSO_AUTH_TOKEN_CODE.SSO_AUTH_TOKEN_CREATE_INVALID_PASSWORD_EXPIRED.code) {
        setUpdatePasswordDialogVisibility(true);
      } else if (error?.code === SSO_AUTH_TOKEN_CODE.SSO_AUTH_TOKEN_CREATE_INVALID_PREVIOUS_TOKEN_EXIST.code) {
        setDuplicateLoginDialogVisibility(true);
      } else if (error?.code === SSO_USER_DEVICE_CODE.SSO_USER_DEVICE_CODE_USER_DEVICE_TOKEN_INVALID.code) {
        sendVerificationCodeForDeviceRegistrationRequest();
        return;
      }

      return { title: "Login Error", message: error?.message || "An error has occurred." };
    },
  });

  const { request: checkIsPasswordConfiguredRequest } = useRequest(UserFlax.checkIsPasswordConfigured({} as any), {
    success: (resp) => {
      const { email, isPasswordConfigured } = resp;

      if (isPasswordConfigured) {
        setCachedEmail(email);
        setNeedPasswordSetup(false);
      } else {
        setNeedPasswordSetup(true);
      }
    },
  });

  useEffect(() => {
    if (!debounceEmail) {
      return;
    }

    const hasNoLocalStorage = !getCachedEmail(debounceEmail);

    if (hasNoLocalStorage) {
      checkIsPasswordConfiguredRequest({ data: { email: debounceEmail } });
    }

    setNeedPasswordSetup(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceEmail]);

  const { request: wakenMss } = useRequest({ ...orchestraMsaApi.waken });

  const login = (email: string, password: string, service: MavenService, deviceToken: string) => {
    loginRequest({ data: { email, password, service, checkPreviousAuthToken: true, deviceToken } });
  };

  return (
    <AuthLayout>
      {initPasswordDialogVisibility && (
        <InitPasswordDialog
          email={email}
          password={password}
          onInit={(password) => {
            const deviceToken = getCookie(DEVICE_KEY);

            setInitPasswordDialogVisibility(false);
            setPassword(password);
            loginRequest({ data: { email, password, service, deviceToken } });
          }}
          onCancel={() => {
            setInitPasswordDialogVisibility(false);
          }}
        />
      )}
      {initPasswordByEmailDialogVisibility && (
        <InitPasswordByEmailDialog
          email={email}
          onInit={(password) => {
            const deviceToken = getCookie(DEVICE_KEY);

            setInitPasswordByEmailDialogVisibility(false);
            setPassword(password);
            loginRequest({ data: { email, password, service, deviceToken } });
          }}
          onCancel={() => {
            setInitPasswordByEmailDialogVisibility(false);
          }}
        />
      )}
      {updatePasswordDialogVisibility && (
        <ResetExpiredPasswordDialog
          email={email}
          password={password}
          onReset={(password) => {
            const deviceToken = getCookie(DEVICE_KEY);

            setUpdatePasswordDialogVisibility(false);
            loginRequest({ data: { email, password, service, deviceToken } });
          }}
          onCancel={() => {
            setVerificationCodeUuid("");
            setUpdatePasswordDialogVisibility(false);
          }}
        />
      )}
      <DuplicateLoginDialog
        header={ReadableRefServiceType(RefServiceType.Suite)}
        messages={{ dialogText: { default: MvnMessage(AUTH_TOKEN_LANG.LOGIN_DUPLICATE_TEXT) } }}
        visibility={duplicateLoginDialogVisibility}
        confirmEventHandler={() => {
          const deviceToken = getCookie(DEVICE_KEY);
          loginRequest({ data: { email, password, service, deviceToken } });
        }}
        cancelEventHandler={() => {
          setVerificationCodeUuid("");
          setDuplicateLoginDialogVisibility(false);
        }}
      />
      <div className="header">
        <h2 className="title">Sign in</h2>
        <p className="help">
          Any problem?{" "}
          <a href="https://www.jnpmedi.com" target="_blank" rel="noopener noreferrer">
            Ask us anything!
          </a>
        </p>
      </div>
      {!verificationCodeUuid && (
        <div className="form">
          <fieldset>
            <legend>Sign in</legend>
            <label htmlFor="email">
              <input
                type="email"
                id="email"
                className="email"
                value={email}
                placeholder="Email"
                onBlur={() => wakenMss()}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                  const deviceToken = getCookie(DEVICE_KEY);

                  if (e.key === "Enter" && isLoginButtonEnabled) {
                    loginRequest({
                      data: { email, password, service, checkPreviousAuthToken: true, deviceToken },
                    });
                  }
                }}
              />
            </label>
            {!needPasswordSetup && (
              <label htmlFor="password">
                <input
                  type="password"
                  id="password"
                  className="password"
                  value={password}
                  placeholder="Password"
                  onBlur={() => wakenMss()}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    const deviceToken = getCookie(DEVICE_KEY);

                    if (e.key === "Enter" && isLoginButtonEnabled) {
                      loginRequest({
                        data: { email, password, service, checkPreviousAuthToken: true, deviceToken },
                      });
                    }
                  }}
                />
              </label>
            )}
          </fieldset>
          <div className="button">
            <CrButton
              loading={loginResponse.busy || sendVerificationCodeForDeviceRegistrationResponse.busy}
              disabled={loginResponse.busy || (!needPasswordSetup && !isLoginButtonEnabled)}
              color="primary"
              onClick={() => {
                const deviceToken = getCookie(DEVICE_KEY);
                email && loginRequest({ data: { email, password, service, checkPreviousAuthToken: true, deviceToken } });
              }}
            >
              Sign in
            </CrButton>
            <p>
              <Link to={preparedAuthLink("/auth/password/find")}>Forgot Password?</Link>
            </p>
            {process.env.REACT_APP_FEATURE_SIGNUP === "true" && (
              <>
                <hr />
                <Link to={preparedAuthLink("/auth/sign-up")} className="cr-button primary">
                  <em>Don't have an account?</em> Sign Up.
                </Link>
              </>
            )}
          </div>
        </div>
      )}
      {verificationCodeUuid && (
        <DeviceVerificationCode
          email={email}
          password={password}
          service={service}
          verificationCodeUuid={verificationCodeUuid}
          login={login}
          loginResponseBusy={loginResponse.busy}
        />
      )}
      {}
    </AuthLayout>
  );
}

interface InitPasswordDialogProps {
  email: string;
  password: string;
  onInit: (password: string) => void;
  onCancel: () => void;
}

function InitPasswordDialog(props: InitPasswordDialogProps) {
  const { email, password, onInit, onCancel } = props;

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const isPasswordValid = isUserPasswordValid(newPassword);
  const isPasswordMatch = newPassword === confirmPassword;

  const { request: initUserPasswordRequest } = useRequest(UserFlax.initUserPassword({} as any), {
    success: () => {
      onInit(newPassword);
    },
    error: (error) => error?.message || "An error has occurred.",
  });

  return (
    <>
      <CrDialog
        visibility={true}
        header={ReadableRefServiceType(RefServiceType.Suite)}
        size="WIDE"
        title={MvnMessage(SSO_USER_LANG.SSO_USER_INIT_PASSWORD_DIALOG_TITLE)}
        confirmDisabled={!isPasswordMatch || !isPasswordValid || !newPassword || !confirmPassword}
        onConfirm={() => {
          initUserPasswordRequest({
            data: {
              email: email,
              password: password,
              newPassword: newPassword,
            },
          });
        }}
        onCancel={onCancel}
        confirm={MvnMessage(COMMON_LANG.CONFIRM)}
        cancel={MvnMessage(COMMON_LANG.CANCEL)}
      >
        <CrInputGroup spacing={7}>
          <CrInput
            label={MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_LABEL)}
            type="password"
            description={
              newPassword && !isUserPasswordValid(newPassword)
                ? MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_DESC)
                : ""
            }
            errorMessage={
              newPassword && email === newPassword ? MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_WARN) : ""
            }
            placeholder={MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_PLACEHOLDER)}
            autoComplete="off"
            onValueChange={(val) => {
              setNewPassword(val);
            }}
          />
          <CrInput
            label={MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_LABEL)}
            type="password"
            description={
              confirmPassword && newPassword !== confirmPassword
                ? MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_DESC)
                : ""
            }
            placeholder={MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_PLACEHOLDER)}
            autoComplete="off"
            onValueChange={(val) => {
              setConfirmPassword(val);
            }}
          />
        </CrInputGroup>
      </CrDialog>
    </>
  );
}

interface InitPasswordByEmailDialogProps {
  email: string;
  onCancel: () => void;
  onInit: (password: string) => void;
}

function InitPasswordByEmailDialog(props: InitPasswordByEmailDialogProps) {
  const { email, onCancel, onInit } = props;

  const [currentStep, setCurrentStep] = useState<"REQUEST_VERIFY_CODE" | "INIT_PASSWORD">("REQUEST_VERIFY_CODE");
  const [dialogTitle, setDialogTitle] = useState(MvnMessage(AUTH_TOKEN_LANG.INITIAL_SETUP_TITLE_EMAIL_VERIFY));
  const [confirmDisable, setConfirmDisable] = useState(true);

  const [verified, setVerified] = useState({ uuid: "", code: "" });
  const [newPassword, setNewPassword] = useState("");

  const { request: initUserPasswordWithVerificationCodeRequest } = useRequest(UserFlax.initUserPasswordWithVerificationCode({} as any), {
    success: () => {
      onInit(newPassword);
    },
    error: (error) => MvnMessage(AUTH_TOKEN_LANG.PASSWORD_CHANGE_FAILED, { message: error || "An error has occurred." }),
  });

  return (
    <>
      <CrDialog
        visibility={true}
        header={ReadableRefServiceType(RefServiceType.Suite)}
        size="WIDE"
        title={dialogTitle}
        confirm={MvnMessage(COMMON_LANG.CONFIRM)}
        confirmDisabled={confirmDisable}
        onConfirm={() => {
          initUserPasswordWithVerificationCodeRequest({
            data: { password: newPassword, verificationCodeUuid: verified.uuid, verificationCode: verified.code },
          });
        }}
        cancel={MvnMessage(COMMON_LANG.CANCEL)}
        onCancel={onCancel}
      >
        <CrInputGroup spacing={7}>
          {currentStep === "REQUEST_VERIFY_CODE" ? (
            <InitPasswordByEmailDialogRequestVerifyCodeStep
              email={email}
              onEmailSent={() => setDialogTitle(MvnMessage(AUTH_TOKEN_LANG.INITIAL_SETUP_TITLE_EMAIL_SENT))}
              onEmailVerified={(uuid, verifiedCode) => {
                setVerified({ uuid, code: verifiedCode });
                setDialogTitle(MvnMessage(AUTH_TOKEN_LANG.INITIAL_SETUP_TITLE_ENTER_PASSWORD));
                setCurrentStep("INIT_PASSWORD");
              }}
            />
          ) : (
            <InitPasswordByEmailDialogInitPasswordStep
              email={email}
              onValueUpdate={(newPassword, initable) => {
                setNewPassword(newPassword);
                setConfirmDisable(!initable);
              }}
            />
          )}
        </CrInputGroup>
      </CrDialog>
    </>
  );
}

interface InitPasswordByEmailDialogRequestVerifyCodeStepProps {
  email: string;
  onEmailSent: () => void;
  onEmailVerified: (uuid: string, verifyCode: string) => void;
}

function InitPasswordByEmailDialogRequestVerifyCodeStep(props: InitPasswordByEmailDialogRequestVerifyCodeStepProps) {
  const { email, onEmailSent, onEmailVerified } = props;

  const [buttonText, setButtonText] = useState(MvnMessage(AUTH_TOKEN_LANG.REQUEST_VERIFICATION_CODE));

  const [showVerifyCodeInput, setShowVerifyCodeInput] = useState(false);
  const [uuid, setUuid] = useState("");
  const [verifyCode, setVerifyCode] = useState("");

  const { request: createUserVerificationCodeForPasswordInitRequest } = useRequest(
    UserVerificationCodeFlax.createUserVerificationCodeForPasswordInit({} as any),
    {
      success: ({ userVerificationCode }) => {
        setUuid(userVerificationCode.uuid);
        setButtonText(MvnMessage(COMMON_LANG.VERIFY));
        setShowVerifyCodeInput(true);
        onEmailSent();

        return MvnMessage(AUTH_TOKEN_LANG.EMAIL_SEND_SUCCEED);
      },
      error: (error) => MvnMessage(AUTH_TOKEN_LANG.EMAIL_SEND_FAILED, { message: error }),
    }
  );

  const { request: verifyUserVerificationCodeRequest } = useRequest(UserVerificationCodeFlax.verifyUserVerificationCode({} as any), {
    success: () => {
      onEmailVerified(uuid, verifyCode);

      return MvnMessage(AUTH_TOKEN_LANG.INITIAL_SETUP_TITLE_ENTER_PASSWORD);
    },
    error: (error) => {
      if (error?.code === SSO_USER_VERIFICATION_CODE_CODE.SSO_USER_VERIFICATION_CODE_BLOCKED.code) {
        setShowVerifyCodeInput(false);
        setVerifyCode("");
        setButtonText(MvnMessage(AUTH_TOKEN_LANG.REQUEST_VERIFICATION_CODE));

        return MvnMessage(AUTH_TOKEN_LANG.VERIFY_LIMIT_EXCEED);
      }

      return MvnMessage(AUTH_TOKEN_LANG.VERIFICATION_NOT_MATCH);
    },
  });

  return (
    <CrInputGroup spacing={5}>
      <CrInput label={MvnMessage(AUTH_TOKEN_LANG.EMAIL)} value={email} disabled={true} />
      {showVerifyCodeInput && (
        <CrInput
          label={MvnMessage(AUTH_TOKEN_LANG.VERIFICATION_CODE)}
          value={verifyCode}
          onValueChange={setVerifyCode}
          placeholder={MvnMessage(AUTH_TOKEN_LANG.VERIFICATION_PLACEHOLDER)}
          maxLength={UserVerificationCodeCriteria.VERIFY_CODE_FOR_PASSWORD_INIT_LENGTH}
          onPaste={(ev) => {
            ev.persist();
            ev.preventDefault();

            setVerifyCode(
              String(ev.clipboardData.getData("Text"))
                .replace(/\D+/g, "")
                .slice(0, UserVerificationCodeCriteria.VERIFY_CODE_FOR_PASSWORD_INIT_LENGTH)
            );
          }}
        />
      )}
      <CrButton
        color="primary"
        onClick={() =>
          !showVerifyCodeInput
            ? createUserVerificationCodeForPasswordInitRequest({ data: { email } })
            : verifyUserVerificationCodeRequest({ data: { email, uuid, code: verifyCode } })
        }
      >
        {buttonText}
      </CrButton>
    </CrInputGroup>
  );
}

interface InitPasswordByEmailDialogInitPasswordStepProps {
  email: string;
  onValueUpdate: (password: string, initable: boolean) => void;
}

function InitPasswordByEmailDialogInitPasswordStep(props: InitPasswordByEmailDialogInitPasswordStepProps) {
  const { email, onValueUpdate } = props;

  const [newPassword, setNewPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (newPassword && isPasswordValid && confirmPassword && newPassword === confirmPassword) {
      onValueUpdate(newPassword, true);
    } else {
      onValueUpdate(newPassword, false);
    }
  }, [newPassword, confirmPassword, isPasswordValid, onValueUpdate]);

  return (
    <>
      <CrInputGroup spacing={5}>
        <CrInput label={MvnMessage(AUTH_TOKEN_LANG.EMAIL)} value={email} disabled={true} />
        <CrInput
          label={MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_LABEL)}
          type="password"
          errorMessage={newPassword && !isPasswordValid ? MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_DESC) : ""}
          placeholder={MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_PLACEHOLDER)}
          autoComplete="off"
          onValueChange={(val) => {
            setNewPassword(val);
            setIsPasswordValid(isUserPasswordValid(val));
          }}
        />
        <CrInput
          label={MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_LABEL)}
          type="password"
          errorMessage={
            confirmPassword && newPassword !== confirmPassword
              ? MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_DESC)
              : ""
          }
          placeholder={MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_PLACEHOLDER)}
          autoComplete="off"
          onValueChange={setConfirmPassword}
        />
      </CrInputGroup>
    </>
  );
}

interface ResetExpiredPasswordDialogProps {
  email: string;
  password: string;
  onReset: (password: string) => void;
  onCancel: () => void;
}

function ResetExpiredPasswordDialog(props: ResetExpiredPasswordDialogProps) {
  const { email, password, onReset, onCancel } = props;

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { request: resetExpiredUserPasswordRequest } = useRequest(UserFlax.resetExpiredUserPassword({} as any), {
    success: () => onReset(newPassword),
    error: (error) => MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_FAIL, { message: error?.message || "An error has occurred." }),
  });

  return (
    <>
      <CrDialog
        header={ReadableRefServiceType(RefServiceType.Suite)}
        visibility={true}
        title={MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_EXPIRED_PASSWORD_DIALOG_TITLE, {
          maxPasswordChangeCycle: AuthTokenCriteria.MAX_PASSWORD_CHANGE_CYCLE_DAY,
        })}
        onConfirm={() => {
          resetExpiredUserPasswordRequest({
            data: {
              email,
              password,
              newPassword,
            },
          });
        }}
        onCancel={onCancel}
        confirmDisabled={!newPassword || !confirmPassword || newPassword !== confirmPassword || newPassword === email}
        confirm={MvnMessage(COMMON_LANG.CONFIRM)}
        cancel={MvnMessage(COMMON_LANG.CANCEL)}
      >
        <CrInput
          label={MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_LABEL)}
          type="password"
          description={
            newPassword && !isUserPasswordValid(newPassword)
              ? MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_DESC)
              : ""
          }
          errorMessage={email === newPassword ? MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_WARN) : ""}
          placeholder={MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_PLACEHOLDER)}
          autoComplete="off"
          onValueChange={setNewPassword}
        />
        <CrInput
          label={MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_LABEL)}
          type="password"
          description={
            confirmPassword && newPassword !== confirmPassword
              ? MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_DESC)
              : ""
          }
          placeholder={MvnMessage(SSO_USER_LANG.SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_PLACEHOLDER)}
          autoComplete="off"
          onValueChange={setConfirmPassword}
        />
      </CrDialog>
    </>
  );
}

const getCachedEmail = (email: string): string | null => {
  const storageKey = `auth:cfg:${email}`;

  return localStorage.getItem(storageKey);
};

const setCachedEmail = (email: string): void => {
  const THIRTY_DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000;
  const storageKey = `auth:cfg:${email}`;
  const afterThirtyDaysMs = (getCurrentDate().getTime() + THIRTY_DAYS_IN_MS).toString();

  localStorage.setItem(storageKey, afterThirtyDaysMs);
};
