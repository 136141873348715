import { useEffect } from "react";
import axios from "axios";
import JSEncrypt from "jsencrypt";
import { useStore } from "react-redux";
import { RootState, SESSION_KEY, SessionState } from "@maven-rest/states";

const ssogw = "ssogw";
const log = (...args: any) =>
  (process.env.REACT_APP_STAGE === "shared" ||
    process.env.REACT_APP_STAGE === "local" ||
    process.env.REACT_APP_STAGE === "develop" ||
    process.env.REACT_APP_STAGE === "production") &&
  console.log(...args);

export function retrieveSSOPayload(): { key: string; redirect: string; uuid: string } | null {
  const payload = retrieveRawSSOPayload();

  try {
    return JSON.parse(atob(payload));
  } catch {
    return null;
  }
}

export function retrieveRawSSOPayload(): string {
  return decodeURIComponent(
    String(document.location.search)
      .slice(1)
      .split("&")
      .find((e) => e.startsWith(`${ssogw}=`))
      ?.split("=")?.[1] || ""
  );
}

export function preparedAuthLink(url: string) {
  const payload = retrieveRawSSOPayload();

  return `${url}${payload ? `${url.includes("?") ? "&" : "?"}${ssogw}=${encodeURIComponent(String(payload))}` : ""}`;
}

export function preparedCallbackLink(session: SessionState) {
  const payload = retrieveSSOPayload();

  log(`Payload(${JSON.stringify(payload)})`);

  if (!payload || !payload.redirect || !payload.key) {
    log("Warn, Invalid Payload.");

    return null;
  } else if (
    process.env.REACT_APP_STAGE !== "shared" &&
    ![
      process.env.REACT_APP_CDMS_FE_URL,
      process.env.REACT_APP_CDMS_FE_URL_ALTER,
      process.env.REACT_APP_CDMS_FE_REAL_URL,
      process.env.REACT_APP_CDMS_FE_BETA_URL,
      process.env.REACT_APP_CDMS_FE_SANDBOX_URL,
      process.env.REACT_APP_BILLING_FE_URL,
      process.env.REACT_APP_BILLING_FE_URL_ALTER,
      process.env.REACT_APP_PORTAL_FE_URL,
      process.env.REACT_APP_PORTAL_FE_URL_ALTER,
      process.env.REACT_APP_AUTH_FE_URL,
      process.env.REACT_APP_AUTH_FE_URL_ALTER,
      process.env.REACT_APP_DOCS_FE_URL,
      process.env.REACT_APP_DOCS_FE_URL_ALTER,
      process.env.REACT_APP_TMF_FE_URL,
      process.env.REACT_APP_TMF_FE_URL_ALTER,
      process.env.REACT_APP_SAFETY_FE_URL,
      // TODO: GS 인증 완료후 TTA 관련 env 제거 필요(2024.01.08)
      process.env.REACT_APP_GS_SAFETY_FE_URL,
      process.env.REACT_APP_SAFETY_FE_URL_ALTER,
      process.env.REACT_APP_BUILDER_FE_URL,
      process.env.REACT_APP_BUILDER_FE_URL_ALTER,
      process.env.REACT_APP_OCR_FE_URL,
      process.env.REACT_APP_OCR_FE_URL_ALTER,
      process.env.REACT_APP_ERECRUITMENT_FE_URL,
      process.env.REACT_APP_ERECRUITMENT_FE_URL_ALTER,
      process.env.REACT_APP_ERECRUITMENT_ADMIN_FE_URL,
      process.env.REACT_APP_ERECRUITMENT_ADMIN_FE_URL_ALTER,
      process.env.REACT_APP_CONVERTER_FE_URL,
      process.env.REACT_APP_CONVERTER_FE_URL_ALTER,
      process.env.REACT_APP_VDR_FE_URL,
      process.env.REACT_APP_VDR_FE_URL_ALTER,
    ]
      .map((e) => e?.split("://")[1])
      .includes(
        String(payload.redirect || "NONE")
          .split("://")[1]
          .split("/auth")[0]
          .split(".")
          .map((e) => (e.match(/^(shared|develop|beta|demo)-[a-z]$/) ? e.split("-")[0] : e))
          .join(".")
      )
  ) {
    log(`Warn, Not allowed callback. (${payload.redirect}) (AUTH: ${process.env.REACT_APP_AUTH_FE_URL})`);
    return null;
  }

  const chunk = 48;
  const encrypted: string[] = [];
  const crypt = new JSEncrypt();
  crypt.setPublicKey(payload.key);

  let rest = String(JSON.stringify(session));
  const loop = Math.ceil(rest.length / chunk);

  for (let i = 0; i < loop && rest; i++) {
    encrypted.push(crypt.encrypt(rest.slice(0, chunk)) || "");
    rest = rest.slice(chunk);
  }

  return `${payload.redirect}${payload.redirect.includes("?") ? "&" : "?"}${ssogw}=${encodeURIComponent(encrypted.join(","))}&uuid=${
    payload.uuid
  }`;
}

function Redirect({ redirectTo, token }: { redirectTo: string; token: string }) {
  useEffect(() => {
    const ax = axios.create();
    delete ax.defaults.headers.common["Authorization"];

    ax.get(`${process.env.REACT_APP_CDMS_URL || process.env.REACT_APP_CDMS_BE_URL}/my`, { headers: { authorization: token } })
      .then((res) => {
        document.location.href = redirectTo;
      })
      .catch((err) => {
        localStorage.removeItem(SESSION_KEY);
        document.location.href = preparedAuthLink("/auth/sign-in");
      });
  });

  return (
    <div className="maven-clinical-cloud--sso state--redirect">
      <span className="app-spinner" />
    </div>
  );
}

export function SSO() {
  const session = useStore<RootState>().getState().session;

  if (session?.token) {
    const callbackUrl = preparedCallbackLink(session);

    if (callbackUrl) {
      log(`Noti, retrieved callbackUrl(${callbackUrl})`);
      return <Redirect redirectTo={callbackUrl} token={session.token} />;
    }

    log("Warn, session.token found but cannot retrieve callback URL");

    document.location.href = "/";
  } else {
    document.location.href = preparedAuthLink("/auth/sign-in");
  }

  return <></>;
}
